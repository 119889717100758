import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Homepage from "./containers/Homepage/Homepage";
import Questionnaire from "./containers/Questionnaire/Questionnaire";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './containers/Login/Login'
import ProgramAnswers from "./containers/ProgramAnswers/ProgramAnswers";
import FestivalAnswers from "./containers/FestivalAnswers/FestivalAnswers";
import ProgramPage from "./containers/ProgramPage/ProgramPage";
import ProgramBusiness from './containers/ProgramBusiness/ProgramBusiness'
import AnteriorPrograms from "./containers/AnteriorPrograms/AnteriorPrograms";
import Blog from "./containers/Blog/Blog";
import Article from "./containers/Article/Article";
import Law from './containers/Law/Law'
import Journalism from "./containers/Journalism/Journalism";
import Fashion from "./containers/Fashion/Fashion";
import Marketing from "./containers/Marketing/Marketing";
import DigitalMarketing from "./containers/DigitalMarketing/DigitalMarketing";
import PR from "./containers/PR/PR";
import Testimonials from "./containers/Testimonials/Testimonials";
import Tourism from "./containers/Tourism/Tourism";
import AnteriorGalleries from "./containers/AnteriorGalleries/AnteriorGalleries";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>

            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/chestionar" element={<Questionnaire />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/raspunsuri-program" element={<ProgramAnswers />} />
            <Route exact path="/raspunsuri-summerwell" element={<FestivalAnswers />} />
            <Route exact path="/program" element={<ProgramPage />} />
            <Route exact path="/program-skillup-business" element={<ProgramBusiness />} />
            <Route exact path="/program-skillup-law" element={<Law />} />
            <Route exact path="/program-journalism" element={<Journalism />} />
            <Route exact path="/programe-anterioare" element={<AnteriorPrograms />} />
            <Route exact path="/blog" element={<Blog/>} />
            <Route exact path="/program-fashion" element={<Fashion/>} />
            <Route exact path="/program-digital-marketing" element={<DigitalMarketing/>} />
            <Route exact path="/program-marketing" element={<Marketing/>} />
            <Route exact path="/program-pr-comunicare" element={<PR/>} />
            <Route exact path="/program-turism" element={<Tourism/>} />
            <Route exact path="/programe-anterioare-testimoniale" element={<Testimonials/>} />
            <Route exact path="/programe-anterioare-galerii" element={<AnteriorGalleries/>} />
            <Route exact path="/article/:id" element={<Article/>} />
          </Routes>
        </Router>
        <ToastContainer autoClose={1000}   position="top-center" />

      </Suspense>
    </div>
  );
}

export default App;
