import React, { useState, useEffect } from 'react';
import '../Homepage/Homepage.scss';
import './DigitalMarketing.scss'
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { NextArrow, PrevArrow } from '../Homepage/CustomArrow';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import mentors from './mentors.json'
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header/Header";
import { formService } from "../../services/FormService";

const DigitalMarketing = () => {
    const { search } = useLocation();
    const [isAltDomeniuChecked, setIsAltDomeniuChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }, []);
    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    var settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeable: true,
        dots: false,
        cssEase: 'linear',
        adaptiveHeight: false,
        centerPadding: '0',
        swipe: true,
        arrows: true,
        pauseOnHover: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    };

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        institution: '',
        city: '',
        interestDomain: [],
        gdprAgreement: false,
        newsletterSubscription: false,
        otherDomain: '',
        parent_phone: '',
        grade: ''
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMentor, setSelectedMentor] = useState(null);

    const [isSkillUpOpen, setSkillUpOpen] = useState(false);

    const toggleSkillUpMenu = () => {
        setSkillUpOpen(!isSkillUpOpen);
    };
    const showModal = (mentor) => {
        setSelectedMentor(mentor);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedMentor(null);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'interestDomain') {
            const newOptions = checked
                ? [...formData.interestDomain, value]
                : formData.interestDomain.filter(option => option !== value);

            setFormData((prevData) => ({
                ...prevData,
                interestDomain: newOptions,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitting(true);

        formService.contactForm(formData).then(response => {
            toast.success(`Cererea ta a fost trimisa cu succes.`);
            setSubmitting(false);
        })
            .catch(error => {
                console.log(error);
                setSubmitting(false);
                toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
            });
    };
    useEffect(() => {
        const params = new URLSearchParams(search);
        const scrollToId = params.get('scrollTo');
        if (scrollToId) {
            const element = document.getElementById(scrollToId);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    }, [search]);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className='standard hp form program law digital'>
            <div className='hp__header'>
                <Link to='/' className='standard__link' onClick={scrollToTop}>
                    <img src='/images/logo.png' alt='logo' className='hp__logo' />
                </Link>
                <MobileMenu />
                <Header />
            </div>
            <div className='hp__banner program__banner digital__banner'>
                <h2 className='program__title'>SkillUP & Digital Marketing</h2>
                <div className='program__banner-info'>

                </div>

            </div>




            <div className='hp__program-section'>
                <h2 className='hp__section-title'>Despre program</h2>
                <p className='hp__section-description hp__about-description'><b>PROGRAMUL Skill<span className='pink'>UP</span>  & Digital Marketing</b> este inițiativa Asociației EDUUP, a cărei misiune este să ofere tinerilor experiențe practice și programe educaționale aplicate, derulate de către experți în domeniul marketingului digital.</p>

            </div>

            <div className='hp__pros-section' id='skillup'>
                <div className='hp__skill-title'>
                    <h2 className='hp__section-title '>Skill<span>UP</span>& Digital Marketing</h2>
                    <ScrollLink to='formular' className='standard__link standard__only-desktop' smooth={true} duration={500}>

                        <button className='hp__banner-button'>Aplică acum</button>
                    </ScrollLink>
                </div>

                <div className='hp__skill-right'>
                    <p className='hp__section-description'><b>Obiectiv: </b>Definirea abilităților și punctelor forte ale elevilor și
                        crearea căilor lor de învățare cu ajutorul practicienilor</p>
                    <p className='hp__section-description'><b>Target: </b> Liceeni din clasele a IX-a - a XII-a și studenți</p>
                    <p className='hp__section-description'><b>Grup: </b>  20-30 participanți </p>
                    <p className='hp__section-description'><b>Durată: </b> 5 workshopuri cu durata de 2h</p>
                    <p className='hp__section-description'><b>Format: </b> fizic, în București</p>

                    <div className="hp__pros-container">
                        <div className='hp__pro hp__pro-title standard__only-mobile'>
                            <h3>Beneficii</h3>

                        </div>

                        <div className="hp__pro hp__pro-yellow law__pro law__benefit">
                            <b><h4>Cunoștințe aplicate </h4></b>
                            <h5>SEO, PPC, social media, copywriting și email marketing prin lecții practice și studii de caz</h5>
                        </div>
                        <div className="hp__pro law__pro hp__pro-red law__benefit">
                            <b><h4>Devoltarea abilităților esențiale </h4></b>
                            <h5>Gândire strategică, creativitate, competențe tehnice</h5>
                        </div>
                        <div className="hp__pro hp__pro-blue law__pro law__benefit">
                            <b><h4>Acces la resurse și programe actuale</h4></b>
                            <h5> Aplicații și software-uri folosite de profesioniști din industrie</h5>
                        </div>
                        <div className='hp__pro hp__pro-title standard__only-desktop law__pro-title'>
                            <h3>Beneficii</h3>

                        </div>
                        <div></div>
                        <div className="hp__pro hp__pro-blue law__pro law__pro-last-left law__benefit">
                            <b><h4>Acces la specialiști din industrie </h4></b>
                            <h5> Interacțiune cu experți din domeniu</h5>
                        </div>

                        <div className="hp__pro hp__pro-yellow law__pro law__pro-last-right law__benefit">
                            <b><h4>Oportunități de networking </h4></b>
                            <h5> Te conectezi cu experți și tineri pasionați de digital marketing</h5>
                        </div>

                    </div>

                </div>
            </div >

            <div className='program__benefits'>
                <h3 className='program__benefits-title'>
                    Ce <span>obții</span> in urma programului
                </h3>
                <div className='program__benefits-wrapper'>
                    <div className='program__benefits-list'>
                        <h4>1. Pregătire cu specialiști în domeniul marketingului digital</h4>
                        <h4>2. Certificat de participare care îți validează competențele dobândite</h4>
                        <h4>3. Acces în comunitate Alumni formată din adolescenți ai cohortelor de digital marketing</h4>

                    </div>
                    <div className='program__benefits-list'>
                        <h4>4. Invitații la evenimente din domeniul marketingului digital</h4>
                        <h4>5. Suport de la experți pe toată durata programului</h4>

                    </div>
                </div>
            </div>
            <img src='/images/digital-marketing-info.png' className='law__info-pic' alt='info' />
            <div className='hp__mentors-section' id='mentori'>
                <h2 className='hp__section-title hp__mentors-title'>Mentorii Skill<span>UP</span>& <span className='yellow-span'>Digital Marketing</span> </h2>
                <Slider  {...settings} className='hp__slider '>
                    {mentors.map(mentor => (
                        <div className='hp__mentor' key={mentor.key}>
                            <img src={mentor.imageUrl} alt='mentor' />
                            <h4>{mentor.name}</h4>
                            <h5>{mentor.function}</h5>
                            <h5>{mentor.brand}</h5>
                            {mentor.bio && (
                                <h5 type='primary' onClick={() => showModal(mentor)} className='hp__see-bio'>
                                    Află mai multe
                                </h5>
                            )}                  </div>
                    ))}

                </Slider>
              {/* <h4 className='law__soon'> În curând</h4>  */}

            </div>


  <div className='hp__resources'>
                <div className='hp__resources-text'>
                    <h3 className='hp__see-program'>Vezi<span className='hp__purple'> programul  </span>și temele de dezbatere ale  <span className='hp__pink'>experților în marketing digital.</span></h3>
                </div>
                <button>Disponibil în curând</button>
                <Link to='/programe-anterioare' className='standard__link' onClick={scrollToTop}>
                    <button className='hp__anterior-programs'>Vezi programele anterioare</button>
                </Link>

            </div>

            <div className='program__benefits program__scolarhip'>
                <h3 className='program__benefits-title law__scolarship-title '>
                    Burse Skill<span>UP</span> & <span className='law__yellow'>Digital Marketing</span>
                </h3>
                <h4 className='hp__section-description law__section-description'><b>Credem în potențialul tău și în puterea educației de a schimba destine!</b></h4>

                <h4 className='hp__section-description law__section-description'><b>Programul SkillUP & Marketing </b>nu este un curs, este o oportunitate de a-ți dezvolta abilitățile, de a te conecta cu practicieni și de la a învăța de la aceștia. Dacă ești curios, pasionat și gata să faci primul pas către pregătirea ta, îți punem la dispoziție o <span className='law__pink'> Bursă pentru a beneficia GRATUIT de program.</span></h4>
                <button class="hp__banner-button bursa-button law__button" onClick={() => scrollToElementOnHome('impact')}>Aplică pentru bursă!</button>
            </div>



            <div className='hp__form-section' id='formular'>
                <img src='/images/form-img.png' className='hp__form-img' />
                <div className='hp__form-right'>
                    <h3 className='hp__form-subtitle program__form-title'>Dacă vrei să te înscrii într-un program Skill<span className='law__pink'>UP</span>,</h3>

                    <h3 className='hp__form-subtitle program__form-subtitle'>completează câmpurile de mai jos:</h3>
                    <form onSubmit={handleSubmit} className='hp__form'>
                        <div className='hp__form-box'>
                            <input
                                type="text"
                                name="name"
                                required
                                placeholder="Nume "
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name="surname"
                                required
                                placeholder="Prenume "
                                value={formData.surname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='hp__form-box'>
                            <input
                                type="email"
                                required
                                name="email"
                                placeholder="Email "
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                required
                                name="city"
                                placeholder="Oras "
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='hp__form-box'>

                            <input
                                type="number"
                                required
                                name="phone"
                                placeholder="Telefon elev"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <input
                                type="number"
                                required
                                name="parent_phone"
                                placeholder="Telefon parinte"
                                value={formData.parent_phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='hp__form-box'>
                            <input
                                type="text"
                                required
                                name="institution"
                                placeholder="Instituția de învățământ"
                                value={formData.institution}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                required
                                name="grade"
                                placeholder="Clasă"
                                value={formData.grade}
                                onChange={handleChange}
                            />

                        </div>
                        <label className='form__label hp__domain-label'>Domenii de interes:</label>

                        <div className='form__checkbox-container'>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Drept"
                                    checked={formData.interestDomain.includes("Drept")}
                                    onChange={handleChange}
                                />
                                <h5>Drept</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Medicina"
                                    checked={formData.interestDomain.includes("Medicina")}
                                    onChange={handleChange}
                                />
                                <h5>Medicina</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Psihologie"
                                    checked={formData.interestDomain.includes("Psihologie")}
                                    onChange={handleChange}
                                />
                                <h5>Psihologie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="IT"
                                    checked={formData.interestDomain.includes("IT")}
                                    onChange={handleChange}
                                />
                                <h5>IT</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Business"
                                    checked={formData.interestDomain.includes("Business")}
                                    onChange={handleChange}
                                />
                                <h5>Business</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Jurnalism"
                                    checked={formData.interestDomain.includes("Jurnalism")}
                                    onChange={handleChange}
                                />
                                <h5>Jurnalism</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Marketing"
                                    checked={formData.interestDomain.includes("Marketing")}
                                    onChange={handleChange}
                                />
                                <h5>Marketing</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Design Grafic"
                                    checked={formData.interestDomain.includes("Design Grafic")}
                                    onChange={handleChange}
                                />
                                <h5>Design Grafic</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Design interior"
                                    checked={formData.interestDomain.includes("Design interior")}
                                    onChange={handleChange}
                                />
                                <h5>Design interior</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Inginerie"
                                    checked={formData.interestDomain.includes("Inginerie")}
                                    onChange={handleChange}
                                />
                                <h5>Inginerie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Arhitectură"
                                    checked={formData.interestDomain.includes("Arhitectură")}
                                    onChange={handleChange}
                                />
                                <h5>Arhitectură</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="interestDomain"
                                    value="Alta"
                                    checked={formData.interestDomain.includes("Alta")}
                                    onChange={handleChange}
                                />
                                <h5>Alt domeniu de interes</h5>
                            </div>
                        </div>
                        {formData.interestDomain.includes("Alta") ? <input
                            type="text"
                            name="otherDomain"
                            placeholder="Domeniu de interes"
                            value={formData.otherDomain}
                            onChange={handleChange}
                        /> : ''}
                        <div className='hp__checkbox'>
                            <input
                                type="checkbox"
                                name="gdprAgreement"
                                checked={formData.gdprAgreement}
                                onChange={handleChange}
                            />
                            <h5>Sunt de acord cu normele GDPR de prelucrare a datelor personale.</h5>
                        </div>
                        <div className='hp__checkbox'>
                            <input
                                type="checkbox"
                                name="newsletterSubscription"
                                checked={formData.newsletterSubscription}
                                onChange={handleChange}
                            />
                            <h5> Sunt de acord să mă abonez la newsletterul
                                EduUP.</h5>
                        </div>
                        <button type="submit" className={`hp__form-submit ${(!formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                            disabled={submitting || !formData.newsletterSubscription || !formData.gdprAgreement}>Înscriere</button>
                    </form>
                </div>

            </div>





            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>
                        <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                            <img src='/images/linkedin.png' alt='linkedin' />
                        </a>
                        <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                            <img src='/images/youtube.png' alt='youtube' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>
                    <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                        <h5>Regulament concurs Summer Well 2024
                        </h5>
                    </a>
                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>hello@eduup.ro</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>

                <Modal
                    title={selectedMentor ? selectedMentor.name : ''}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="close" onClick={handleCancel}>
                            Închide
                        </Button>,
                    ]}
                >
                    {selectedMentor && (
                        <>
                            <p className='hp__mentor-bio'>{selectedMentor.bio1}</p>

                            <p className='hp__mentor-bio'>{selectedMentor.bio2}</p>
                            <a href={selectedMentor.linkedinUrl} target="_blank" rel="noopener noreferrer">
                                Vezi profilul LinkedIn
                            </a>
                        </>
                    )}
                </Modal>
            </div>
        </div >
    )
}
export default DigitalMarketing
