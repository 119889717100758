import React from 'react';
import './CadranArrow.scss';
export const NextCadranArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: `url(/images/next.png) no-repeat center` }}
            onClick={onClick}
        />
    );
};

export const PrevCadranArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: `url(/images/back.png) no-repeat center` }}
            onClick={onClick}
        />
    );
};