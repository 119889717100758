import React, { useState, useEffect, useRef} from 'react';
import '../Homepage/Homepage.scss';
import '../Testimonials/Testimonials.scss'
import '../../resources/main.scss';
import './AnteriorGalleries.scss'

import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import mentors from '../Homepage/mentors.json'
import Header from "../../components/Header/Header";
import Masonry from 'masonry-layout';
import { anteriorGalleriesService } from "../../services/AnteriorGalleriesService.jsx";
import InfiniteScroll from 'react-infinite-scroll-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Gallery } from 'react-grid-gallery';

const { Option } = Select;

const programs = [
    {
        id: 1,
        name: 'SkillUP & Business by ASUS Business',
        mentors: '/images/mentori-program1.png',
        agenda: '/documents/agenda.pdf',
        gallery: '/galerii/galerie-program1',
        testimonials: '/testimoniale/testimoniale-program1'
    }
];
const AnteriorGalleries = () => {

    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };

    const [selectedProgram, setSelectedProgram] = useState(programs[0]);
    const [isSkillUpOpen, setSkillUpOpen] = useState(false);

    const [totalFiles, setTotalFiles] = useState(0);
    const [gallery, setGallery] = useState({});
    const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1 });
    const [isFetching, setIsFetching] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const galleryRef = useRef(null);

    useEffect(() => {
        anteriorGalleriesService.getGallery('v3pmY3KaIVfddpUHSYXDFgyFzps4xdR4', 1)
            .then(response => {
              setTotalFiles(response.data.total_files);
                const currentGallery = response.data.gallery;

                setGallery(currentGallery);

                const newPhotos = response.data.photos.map(file => {
                  const newFile = {
                    src: file.enhanced_url,
                    caption: file.file_name,
                    message:file.message,
                    thumbnail: file.thumbnail_url,
                    id:file.id,
                    width: 1, // Temporary values to ensure image proportions
                    height: 1 // Temporary values to ensure image proportions
                  };
                  const img = new Image();
                  img.src = file.enhanced_url;
                  img.onload = function () {
                    newFile.width = this.width;
                    newFile.height = this.height;
                    setPhotos(prevPhotos => [...prevPhotos]); // Trigger re-render
                  };

                  return newFile;
                });

                console.log(newPhotos);

                setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);

                setPagination({
                  current_page: response.data.current_page,
                  total_pages: response.data.total_pages,
                });
            })
            .catch(error => {

            });
    }, [navigate]);

    useEffect(() => {
      if (galleryRef.current && galleryRef.current.clientHeight < window.innerHeight && pagination.current_page < pagination.total_pages) {
        fetchMoreData();
      }
    }, [photos]);

    const fetchMoreData = () => {
      if (pagination.current_page < pagination.total_pages) {
        fetchPhotos('v3pmY3KaIVfddpUHSYXDFgyFzps4xdR4', pagination.current_page + 1);
      }
    };

    const fetchPhotos = (token, page = 1, reset = false) => {

      if (isFetching || page > pagination.total_pages) return;
      if (!gallery.is_public) return;

      setIsFetching(true);

      anteriorGalleriesService.getGallery(token, page)
        .then(response => {
          const newPhotos = response.data.photos.map(file => {
            const newFile = {
              src: file.enhanced_url,
              caption: file.file_name,
              message:file.message,
              thumbnail: file.thumbnail_url,
              id:file.id,
              width: 1, // Temporary values to ensure image proportions
              height: 1 // Temporary values to ensure image proportions
            };
            const img = new Image();
            img.src = file.enhanced_url;
            img.onload = function () {
              newFile.width = this.width;
              newFile.height = this.height;
              setPhotos(prevPhotos => [...prevPhotos]); // Trigger re-render
            };

            return newFile;
          });

          setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);

          setPagination({
            current_page: response.data.current_page,
            total_pages: response.data.total_pages,
          });
          setIsFetching(false);
        })
        .catch(error => {
          setIsFetching(false);
        });
    };

    const openLightbox = (index) => {
      const img = new Image();
      img.src = photos[index].src;
      img.onload = () => {
        setIndex(index);
        setIsOpen(true);
      };
    };

    const handleCloseLightbox = () => {
      setIsOpen(false);
      setIndex(-1); // Reset the index when closing the Lightbox
    };

    const handleMovePrev = () => setIndex((index + photos.length - 1) % photos.length);

    const handleMoveNext = () => {
      if (index === photos.length - 1) {
        setIsFetching(true);

        anteriorGalleriesService.getGallery('v3pmY3KaIVfddpUHSYXDFgyFzps4xdR4', pagination.current_page + 1)
          .then(response => {
            const newPhotos = response.data.photos.map(file => {
              const newFile = {
                src: file.enhanced_url,
                caption: file.file_name,
                message:file.message,
                thumbnail: file.thumbnail_url,
                id:file.id,
                width: 1, // Temporary values to ensure image proportions
                height: 1 // Temporary values to ensure image proportions
              };
              const img = new Image();
              img.src = file.enhanced_url;
              img.onload = function () {
                newFile.width = this.width;
                newFile.height = this.height;
                setPhotos(prevPhotos => [...prevPhotos]); // Trigger re-render
              };

              return newFile;
            });

            setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
            setPagination({
              current_page: response.data.current_page,
              total_pages: response.data.total_pages,
            });
            setIsFetching(false);
            setIndex((index + 1) % [...photos, ...newPhotos].length);
          })
          .catch(error => {
            setIsFetching(false);
          });

      } else {
        setIndex((index + 1) % photos.length);
      }
    };


    const toggleSkillUpMenu = () => {
        setSkillUpOpen(!isSkillUpOpen);
    };

    const handleChange = (value) => {
        const program = programs.find((p) => p.id === value);
        setSelectedProgram(program);
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const masonryRef = useRef(null);

    return (
        <div className='standard hp form program anterior'>
            <div className='hp__header'>
                <Link to='/' className='standard__link' onClick={scrollToTop}>
                    <img src='/images/logo.png' alt='logo' className='hp__logo' />
                </Link>
                <MobileMenu />
                <Header />
            </div>
            <div className='hp__banner program__banner anterior__banner'>
                <h2 className='program__title'>Programe anterioare/Galerie</h2>


            </div>
            <div className='standard__only-mobile'>


                <div className="anterior__custom-select">
                    <select onChange={(e) => handleChange(Number(e.target.value))} defaultValue="">
                        <option value="" disabled hidden>Selectează programul</option>
                        {programs.map((program) => (
                            <option key={program.id} value={program.id}>
                                {program.name}
                            </option>
                        ))}
                    </select>
                    <img src="/images/down.png" alt='down' className='anterior__down' />
                </div>
                <div className="anterior__details">
                    <h3>{selectedProgram.name}</h3>
                    <div className="testimonials__container" ref={masonryRef}>

                    </div>
                </div>
            </div>

            <div className='anterior__wrapper'>
                <div className='anterior__left-section testimonials__left-section'>
                    <h3>Alege programul</h3>
                    <h4>Business by Asus </h4>
                </div>
                <div className='anterior__center-section testimonials__central-section'>
                    <h3 className='testimonials__title'>SkillUp & Business by ASUS Business / <span className='testimonials__lighter'>Galerie</span>  </h3>
                    <div className="anterior-galleries__container">

                    {photos.length ? <div ref={galleryRef} className='gallery'>
                      <InfiniteScroll
                        dataLength={photos.length}
                        next={fetchMoreData}
                        hasMore={pagination.current_page < pagination.total_pages}
                        loader={<h4>Loading...</h4>}
                      >
                        <Gallery
                          images={photos}
                          onClick={(index) => openLightbox(index)}
                          enableImageSelection={false}
                        />
                      </InfiniteScroll>
                    </div> : (
                      <p>Nu exista poze in galerie sau galeria nu este publica.</p>
                    )}

                    {isOpen && index >= 0 && (
                      <Lightbox
                        mainSrc={photos[index].src}
                        nextSrc={photos[(index + 1) % photos.length].src}
                        prevSrc={photos[(index + photos.length - 1) % photos.length].src}
                        onCloseRequest={handleCloseLightbox}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                        imageCaption={photos[index].message}
                      />
                    )}


                    </div>
                </div>

            </div>


            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>

                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>asociatiaeduup@gmail.com</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>


            </div>
        </div >
    )
}
export default AnteriorGalleries
