import React, { useState, useEffect } from 'react';
import './Blog.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header'
import MetaTags from 'react-meta-tags';
import { blogService } from '../../services/BlogService';
import Moment from 'react-moment';
import DOMPurify from 'dompurify';
import { toast, ToastContainer } from 'react-toastify';
import { Link as ScrollLink } from 'react-scroll';
import MobileMenu from "../../components/Menu/MobileMenu";
import '../Homepage/Homepage.scss'
import { useNavigate } from 'react-router-dom';

import '../../resources/main.scss'
const Blog = () => {
    const [newsletterInfo, setNewsletterInfo] = useState({ name: '', email: '' });
    const [articles, setArticles] = useState([]);
    const [newestArticle, setNewestArticle] = useState({ main_image: {} });
    const navigate = useNavigate();
    const scrollToElementOnHome = (sectionId) => {
        navigate('/', { state: { scrollTo: sectionId } });
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewsletterInfo(prevInfo => ({ ...prevInfo, [name]: value }));
    };

    useEffect(() => {
        blogService.getArticles()
            .then(response => {
                const fetchedArticles = response.data.articles;
                setArticles(fetchedArticles);
                setNewestArticle(fetchedArticles[fetchedArticles.length - 1]);
            })
            .catch(err => console.error(err));
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        blogService.sendContactMessage(newsletterInfo)
            .then(() => {
                toast.success('Subscripția ta a fost înregistrată!', { position: toast.POSITION.TOP_CENTER });
            })
            .catch(() => {
                toast.error('Ne pare rău, a intervenit o eroare. Revino mai târziu sau, mai bine, contactează-ne!', { position: toast.POSITION.TOP_CENTER });
            });
    };

    const blogSettings = {
        infinite: true,
        speed: 200,
        swipe: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        centeredMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 4 } },
            { breakpoint: 800, settings: { slidesToShow: 3 } },
            { breakpoint: 480, settings: { slidesToShow: 2 } }
        ]
    };

    const settings = {
        infinite: true,
        speed: 200,
        swipe: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        centeredMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false
    };

    return (
        <div className='standard blog hp'>
            <div className='hp__header'>
                <Link to='/' className='standard__link' onClick={scrollToTop}>
                    <img src='/images/logo.png' alt='logo' className='hp__logo' /></Link>
                <MobileMenu />
                <Header />
            </div>
            <div className='blog__banner'>
                <h4 className='blog__title'>Blog</h4>
            </div>
            <div className='blog__container'>

                <div className='article__articles-wrapper blog__articles'>
                    {articles.slice(0, 3).map(articleItem => (
                        <div key={articleItem.id} className='blog__article-container'>
                            <Link onClick={scrollToTop} to={`/article/${articleItem.slug}`} className='standard__link blog__article-whole'>
                                {articleItem.main_image && articleItem.main_image.url ? (
                                    <img src={process.env.REACT_APP_BLOG_API_URL + articleItem.main_image.url} className='blog__article-img' alt='wedding' />
                                ) : (
                                    <img src="/images/wedding.jpg" className='blog__article-img article__blog-img' alt='wedding' />
                                )}
                                <h5 className='blog__date'>
                                    <img src='/images/clock.png' alt='clock' />
                                    <Moment format="DD/MM/YYYY">
                                        {articleItem.updated_at}
                                    </Moment>
                                </h5>
                                <h3 className='blog__article-title'>{articleItem.title}</h3>
                                {articleItem.description ? (
                                    <h3
                                        className='blog__description'
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleItem.description) }}
                                    />
                                ) : (
                                    <h3 className='blog__description'>Descriere indisponibilă</h3>
                                )}
                                <button className='blog__read-more'>Citește</button>
                            </Link>
                        </div>
                    ))}
                </div>

            </div>

            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>
                        <a href="https://www.linkedin.com/company/asociatia-eduup/" target="_blank" className='standard__link'>
                            <img src='/images/linkedin.png' alt='linkedin' />
                        </a>
                        <a href="https://www.youtube.com/@AsociatiaEduUP" target="_blank" className='standard__link'>
                            <img src='/images/youtube.png' alt='youtube' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>
                    <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                        <h5>Regulament concurs Summer Well 2024
                        </h5>
                    </a>
                    <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('despre')}>Despre EduUp</h5>
                    </ScrollLink>
                    <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>

                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </ScrollLink>
                    <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('mentori')}>Mentori</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('impact')}>Impact Social</h5>
                    </ScrollLink>
                    <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('parteneri')}>Parteneri</h5>
                    </ScrollLink>
                    <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5 onClick={() => scrollToElementOnHome('intrebari-frecvente')}>Întrebări frecvente</h5>
                    </ScrollLink>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>hello@eduup.ro</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                    <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
                        <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Blog;