import axios from 'axios';

export const anteriorGalleriesService={
    getGallery
}

function getGallery(galleryToken, pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/public_galleries/${galleryToken}/photos?page=${pageNumber ? pageNumber : 1}`;

  const requestOptionsPost = {
    method: 'GET',
    url,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };

  return axios(requestOptionsPost);
}
